import React from 'react';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../common/constants';

export const Header = ({dataHook}: {dataHook: string}) => {
  const {
    paymentRequestSettingsStore: {paymentRequestSettings},
    isOneColumnView,
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldUseCheckoutHeaderOnCheckout = experiments.enabled(SPECS.UseCheckoutHeaderOnCheckout);

  return (
    <header className={shouldUseCheckoutHeaderOnCheckout ? classes.header : classes.headerOld}>
      <div
        className={st(shouldUseCheckoutHeaderOnCheckout ? classes.innerHeader : classes.innerHeaderOld)}
        data-hook={dataHook}>
        <StoreNameAndLogo
          logoUrl={paymentRequestSettings.logoUrl}
          storeName={paymentRequestSettings.storeName}
          checkoutHeader={paymentRequestSettings.checkoutHeader}
          isOneColumnView={isOneColumnView}
        />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
